/*eslint-disable */
var $ = window.jQuery = require('jquery');

import commonSocket from "@/services/socket/";
import {
  mapGetters,
} from 'vuex';
import videoSocketRef from "@/services/videoNotificationSocket/";

export default {
  name: 'jisti-video-confreness',
  components: {},
  props: [],
  data() {
    return {
      selfUserProfileDetails:null,
      apiObj:null,
      domain:process.env.VUE_APP_VIDEO_DOMAIN,
      callerRoomId:'',
      selfUserFullName:'',
      selfUserEmail:'',
      toolbarState:false,
      isAudioMuted:false,
      isVideoMuted:false,
      fullScreenState:true,
      selfUserId:''
    }
  },
  computed: {
    ...mapGetters([
      'selfUserDetailGetters',
    ]),
    getUserDetailsInfo() {
      this.selfUserProfileDetails = this.$store.getters.selfUserDetailGetters.data;
      return
    },
    setIntialUserIdFun(){
      if(this.selfUserProfileDetails){
        this.selfUserFullName = this.selfUserProfileDetails.user.first_name + ' ' + this.selfUserProfileDetails.user.last_name + ' ' + '#' + this.selfUserProfileDetails.registration_no;
        this.selfUserEmail = this.selfUserProfileDetails.user.email;
      }
    }
  },
  mounted() {
    if (!location.hash) {
      location.hash = Math.floor(Math.random() * 0xFFFFFF).toString(16);
    }
    this.getUserDetailsInfo;
    this.setIntialUserIdFun;
    console.log(this.selfUserProfileDetails, "selfUserProfileDetails")
    this.StartMeetingMethod(this.callerRoomId, this.selfUserFullName, this.selfUserEmail)
  },
  created(){
    this.callerRoomId = location.hash.substring(1);
    this.selfUserId = localStorage.getItem('LoginedUserID')
  },
  methods: {
    StartMeetingMethod(roomName, dispNme, userEmail) {
      var __self = this
      const options = {
        roomName: roomName,
        width: '100%',
        height: '100%',
        parentNode: document.querySelector('#jitsi-meet-conf-container'),
        userInfo: {
          email:userEmail,
          displayName: dispNme
        },
        configOverwrite: {
          doNotStoreRoom: false,
          startWithVideoMuted: false,
          startWithAudioMuted: false,
          enableWelcomePage: false,
          prejoinPageEnabled: false,
          disableRemoteMute: false,
          remoteVideoMenu: {
            disableKick: true
          },
          enableNoAudioDetection: false,
          enableNoisyMicDetection: false,
          enableWelcomePage: false
        },
        interfaceConfigOverwrite: {
          filmStripOnly: true,
          TOOLBAR_BUTTONS: [ 'chat', 'tileview', 'desktop', 'hangup', 'microphone', 'camera', 'fullscreen', 'select-background', 'filmstrip'],
          TOOLBAR_ALWAYS_VISIBLE: false,
          DEFAULT_BACKGROUND: '#0000000',
          HIDE_INVITE_MORE_HEADER: false,
        },
      }
      this.apiObj = new JitsiMeetExternalAPI(this.domain, options);
      this.apiObj.on('readyToClose', () => {
        __self.toolbarState = false
        window.location.href = '/video/conference/left/room/#' + location.hash.substring(1);
      })
    },
  },
  beforeCreate: function () {
    // if(commonSocket.isOpen()) {
    //   commonSocket.close();
    // }
    // if(videoSocketRef.isOpen()) {
    //   videoSocketRef.close();
    // }
    document.body.classList.add('video_conference_page');
  },
  beforeRouteLeave: function (to, from, next) {
    document.body.classList.remove('video_conference_page');
    next();
  },
  watch: {
    getUserDetailsInfo() {
      return this.$store.getters.selfUserDetailGetters
    },
    setIntialUserIdFun(){
      if(this.selfUserProfileDetails ){
        this.selfUserFullName = this.selfUserProfileDetails.user.first_name + ' ' + this.selfUserProfileDetails.user.last_name + ' ' + '#' + this.selfUserProfileDetails.registration_no;
        this.selfUserEmail = this.selfUserProfileDetails.user.email;
      }
    }
  },
}