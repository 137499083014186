import { render, staticRenderFns } from "./one-to-one-call.html?vue&type=template&id=5b4587a7&scoped=true&external"
import script from "./one-to-one-call.js?vue&type=script&lang=js&external"
export * from "./one-to-one-call.js?vue&type=script&lang=js&external"
import style0 from "./one-to-one-call.scss?vue&type=style&index=0&id=5b4587a7&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b4587a7",
  null
  
)

export default component.exports